import {
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Link,
} from "@chakra-ui/next-js";


export default function NextraCompatibleButtonLink({
  href,
  variant,
  isExternal,
  noFollow,
  prefetch=false,
  children,
  ...rest
}: {
  href: string,
  variant: string,
  isExternal?: boolean,
  noFollow?: boolean,
  prefetch?: boolean,
  children: React.ReactNode,
  [rest: string]: any
}) {
  const textColor = useColorModeValue(variant == "brand"? "white" : "secondaryGray.900", "white");

  return (
    <Link
      prefetch={prefetch}
      href={href} 
      isExternal={isExternal} 
      rel={noFollow? "nofollow" : undefined}
      style={{
        height: "50px",
      }}
    >
      <Button
        variant={variant}
        style={{
          paddingTop: "20px",
          paddingBottom: "20px",
          height: "50px",
          width: "300px",
          fontWeight: "500",
          fontSize: "18px",
          color: textColor,
          display: "flex",
          justifyContent: "space-around",
        }}
        as="span" // hack to prevent nextra button css
        {...rest}
    >
        {children}
      </Button>
    </Link>
  )
}